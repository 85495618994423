
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";

import axios from "axios";

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Dropdown,
    Button,
  },
  props: {
    admin: Object,
  },
  emits: ["close", "update:admin", "saved"],
  data() {
    return {
      errors: {} as { [key: string]: string | null },
      options: {
        positions: [],
      },
      roleName: "",
    };
  },
  created() {
    this.searchPositions("");
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getRoleIDByAdminPosition(id: number) {
      const { data } = await axios.get("/api/admin-positions", {
        params: {
          q: JSON.stringify({
            id,
          }),
        },
      });

      return data.role_id;
    },
    async getRoleName(id: number) {
      const { data } = await axios.get("/api/admins/role/" + id);
      return data.name;
    },
    async searchPositions(query: string) {
      const { data } = await axios.get(`/api/admins/positions-lookup`, {
        params: {
          q: JSON.stringify({
            query,
          }),
        },
      });

      this.options.positions = data;
    },
    async emitPatch(patch: any) {
      this.$emit("update:admin", {
        ...this.admin,
        ...patch,
      });

      if (patch.position_id) {
        this.roleName = await this.getRoleName(
          await this.getRoleIDByAdminPosition(patch.position_id)
        );
      }

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async saveAdmin() {
      try {
        await axios.post("/api/admins", this.admin);
        this.$emit("saved");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
