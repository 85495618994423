
import { defineComponent } from "vue";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import { Pagination, Sorting } from "@/use/types";
import Editor from "./Editor.vue";

import axios from "axios";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  adminToCreate: any;
}

interface IQuery {
  pagination: null | Pagination;
  sorting: Sorting;
}

export function getDefaultQuery(): IQuery {
  return {
    sorting: { column: "name", direction: "asc" },
    pagination: { limit: 10, offset: 0 },
  };
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

const dataColumns = [
  {
    id: "name",
    name: "Имя пользователя",
    selected: true,
    sortable: true,
    freezed: true,
    type: "text",
  },
  {
    id: "id",
    name: "ID",
    selected: true,
    sortable: true,
  },
  {
    id: "position",
    name: "Должность",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "role",
    name: "Роль",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "login",
    name: "логин",
    selected: true,
  },
  {
    id: "password",
    name: "Пароль",
    selected: true,
  },
];

export default defineComponent({
  components: {
    AppPage,
    Button,
    DataTable,
    Editor,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      adminToCreate: null,
    };
  },
  mounted: function () {
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  async created() {
    this.load();
  },
  methods: {
    close() {
      this.adminToCreate = null;
    },
    openEditor(row: any) {
      this.adminToCreate = row || {
        name: "",
        position_id: null,
        role_id: null,
        login: "",
      };
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const { data } = await axios.get("/api/admins", {
          params: { q: JSON.stringify(this.filter) },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
