<template>
  <div :class="['popup', className]">
    <div class="popup__overlay" @click="this.$emit('close')" />
    <div :class="['popup__inner', className + '__inner']">
      <div class="icon__close" v-if="closeIcon" @click="this.$emit('close')">
        <span />
        <span />
      </div>
      <div :class="['popup__content', className + '__content']">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    className: String,
    closeIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px;
  left: 0;
  top: 0;
  z-index: 9999;

  &._center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: fade-out(#000, 0.4);
  }

  &__inner {
    padding: 40px;
    background: #fff;
    margin: auto;
    max-width: 100%;
  }

  &:not(.editor) {
    .popup__inner {
      width: 600px;
    }
  }
}
</style>
